import React, { memo } from 'react'

const Skills = () => (
  <section className='fxt-skill-section' id='skills'>
    <div className='container'>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='fxt-skill-wrap-layout2'>
            <div className='item-heading'>
              <h2 className='item-title'>Technical Skills</h2>
              <p>My favorite tools</p>
            </div>
            <ul className='fxt-skill-box-layout2'>
              <li className='single-item'>
                <label>React.js</label>
                <div className='progress'>
                  <div
                    className='progress-bar wow slideInLeft'
                    data-wow-delay='0ms'
                    data-wow-duration='2000ms'
                    role='progressbar'
                    style={{
                      width: '100%',
                      visibility: 'visible',
                      animationDuration: '2000ms',
                      animationDelay: '0ms',
                      animationName: 'slideInLeft'
                    }}
                  >
                    <span>100%</span>
                  </div>
                </div>
              </li>
              <li className='single-item'>
                <label>Node.js</label>
                <div className='progress'>
                  <div
                    className='progress-bar wow slideInLeft'
                    data-wow-delay='200ms'
                    data-wow-duration='2000ms'
                    role='progressbar'
                    style={{
                      width: '100%',
                      visibility: 'visible',
                      animationDuration: '2000ms',
                      animationDelay: '200ms',
                      animationName: 'slideInLeft'
                    }}
                  >
                    <span>100%</span>
                  </div>
                </div>
              </li>
              <li className='single-item'>
                <label>API</label>
                <div className='progress'>
                  <div
                    className='progress-bar wow slideInLeft'
                    data-wow-delay='300ms'
                    data-wow-duration='2000ms'
                    role='progressbar'
                    style={{
                      width: '100%',
                      visibility: 'visible',
                      animationDuration: '2000ms',
                      animationDelay: '300ms',
                      animationName: 'slideInLeft'
                    }}
                  >
                    <span>100%</span>
                  </div>
                </div>
              </li>
              <li className='single-item'>
                <label>Figma</label>
                <div className='progress'>
                  <div
                    className='progress-bar wow slideInLeft'
                    data-wow-delay='400ms'
                    data-wow-duration='2000ms'
                    role='progressbar'
                    style={{
                      width: '50%',
                      visibility: 'visible',
                      animationDuration: '2000ms',
                      animationDelay: '400ms',
                      animationName: 'slideInLeft'
                    }}
                  >
                    <span>50%</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='fxt-skill-wrap-layout2'>
            <div className='item-heading'>
              <h2 className='item-title'>Professional Skills</h2>
              <p>How I like to work</p>
            </div>
            <ul className='fxt-skill-box-layout2'>
              <li className='single-item'>
                <label>Agility</label>
                <div className='progress'>
                  <div
                    className='progress-bar wow slideInLeft'
                    data-wow-delay='0ms'
                    data-wow-duration='2000ms'
                    role='progressbar'
                    style={{
                      width: '100%',
                      visibility: 'visible',
                      animationDuration: '2000ms',
                      animationDelay: '0ms',
                      animationName: 'slideInLeft'
                    }}
                  >
                    <span>100%</span>
                  </div>
                </div>
              </li>
              <li className='single-item'>
                <label>TDD / BDD</label>
                <div className='progress'>
                  <div
                    className='progress-bar wow slideInLeft'
                    data-wow-delay='200ms'
                    data-wow-duration='2000ms'
                    role='progressbar'
                    style={{
                      width: '100%',
                      visibility: 'visible',
                      animationDuration: '2000ms',
                      animationDelay: '200ms',
                      animationName: 'slideInLeft'
                    }}
                  >
                    <span>100%</span>
                  </div>
                </div>
              </li>
              <li className='single-item'>
                <label>KISS &amp; Clean-Code</label>
                <div className='progress'>
                  <div
                    className='progress-bar wow slideInLeft'
                    data-wow-delay='300ms'
                    data-wow-duration='2000ms'
                    role='progressbar'
                    style={{
                      width: '100%',
                      visibility: 'visible',
                      animationDuration: '2000ms',
                      animationDelay: '300ms',
                      animationName: 'slideInLeft'
                    }}
                  >
                    <span>100%</span>
                  </div>
                </div>
              </li>
              <li className='single-item'>
                <label>Team Leading</label>
                <div className='progress'>
                  <div
                    className='progress-bar wow slideInLeft'
                    data-wow-delay='400ms'
                    data-wow-duration='2000ms'
                    role='progressbar'
                    style={{
                      width: '80%',
                      visibility: 'visible',
                      animationDuration: '2000ms',
                      animationDelay: '400ms',
                      animationName: 'slideInLeft'
                    }}
                  >
                    <span>80%</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default memo(Skills)
